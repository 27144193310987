@import "../../index.scss";
.sidebar {
  // position: fixed;
  // width: 80px;
  display     : none;
  top         : 70px;
  background  : #fff;
  bottom      : 0;
  left        : 0;
  overflow    : hidden;
  border-right: 1px solid #eee;
  z-index     : 10;
  // .side-title {
  //   position        : relative;
  //   width           : 600px;
  //   height          : 60px;
  //   top             : 600px;
  //   border-left     : 1px solid #e6eaea;
  //   line-height     : 60px;
  //   letter-spacing  : 0.27em;
  //   font-size       : 11px;
  //   text-transform  : uppercase;
  //   text-align      : center;
  //   cursor          : default;
  //   font-weight     : bold;
  //   color           : #4d4d4d;
  //   transform       : rotate(-90deg);
  //   transform-origin: left top;
  // }
  .side-social {
    position   : absolute;
    bottom     : 0;
    left       : 0;
    width      : 100%;
    // border-top : 1px solid #e6eaea;
    padding-top: 20px;
    .social-name {
      position  : absolute;
      top       : -70px;
      // top       : -15px;
      font-size : 10px;
      color     : #111;
      display   : block;
      width     : 100%;
      text-align: center;
    }
    ul.social-list {
      list-style: none;
      padding   : 0;
      li {
        float     : left;
        width     : 100%;
        margin    : 0;
        // margin-bottom: 10px;
        text-align: center;
        // &:hover {
        //   a {
        //     span {
        //       .social * {
        //         opacity: 0.7;
        //         // fill: #fff !important;
        //         fill: #fff !important;
        //       }
        //     }
        //   }
        // }
        a {
          display   : block;
          width     : 100%;
          height    : 100%;
          transition: all 0.7s ease-out;
          span {
            font-size: 1.7rem;
            display  : block;
            padding  : 0 10px 5px;
            img {
              max-width : 40px;
              opacity   : 0.5;
              transition: all 0.4s ease-out;
            }
            object {
              display       : block;
              margin        : 0 auto;
              max-width     : 45px;
              // padding: 5px 0px 0 5px;
              opacity       : 0.5;
              transition    : all 0.4s ease-out;
              user-select   : none;
              pointer-events: none;
            }
          }
          &:hover {
            span {
              object {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(sm) {
    position      : fixed;
    width         : 60px;
    top           : 70px;
    // display : block;
    display       : flex;
    flex-direction: column;
    .bar-wrap {
      height : auto;
      // background: green;
      display: flex;
      &.social {
        margin-top: auto;
        border-top: 1px solid #e6eaea;
      }
      &.title {
        background   : #fff;
        z-index      : 1;
        border-bottom: 1px solid #e6eaea;
        min-height   : 350px;
        height       : calc(100% - 40vh);
      }
      span.side-title {
        position        : relative;
        display         : block;
        // width           : 600px;
        // height          : 60px;
        // top             : 600px;
        white-space     : nowrap;
        // border-left     : 1px solid #e6eaea;
        // line-height     : 60px;
        // line-height     : 50vh;
        line-height     : 0;
        letter-spacing  : 0.27em;
        font-size       : 11px;
        text-transform  : uppercase;
        text-align      : center;
        cursor          : default;
        font-weight     : bold;
        color           : #4d4d4d;
        transform       : rotate(-90deg);
        transform-origin: center top;
        margin          : auto -20px;
        // transform-origin: left top;
      }
    }
  }
}