@import "../../index.scss";
nav.navbar {
  font-family: $fontHeader;
  background : #fff;
  box-shadow : 2px 0 35px #ddd;
  padding    : 1rem 1rem 0;
  min-height : 70px;
  .navbar-toggler {
    display     : block;
    border-color: #333;
    .navbar-toggler-icon {
      min-height: 27px;
    }
  }
  .navbar-brand {
    padding: 0;
    margin : 0 0 auto;
    @include media-breakpoint-down(sm) {
      margin: auto 0;
      img {
        height: 35px;
      }
    }
  }
  .navbar-collapse {
    display   : block;
    margin-top: -110vh;
    flex-basis: auto;
    width     : 100%;
    transition: all 0.5s ease-out;
    ul.navbar-nav {
      flex-direction: column;
      float         : none;
      display       : block;
      width         : 100%;
      flex-direction: row;
      float         : right;
      li.nav-item {
        position      : relative;
        flex-grow     : 1;
        flex-shrink   : 0;
        height        : 55px;
        // height: 60px;
        font-size     : 14px;
        text-align    : center;
        text-transform: uppercase;
        line-height   : 40px;
        // line-height: 60px;
        letter-spacing: 1px;
        cursor        : pointer;
        z-index       : 10;
        background    : #fff;
        @media screen and (max-height: 600px) {
          height: 45px;
        }
        .nav-link {
          padding       : 0 20px 17px;
          word-spacing  : 0;
          text-align    : center;
          color         : rgba(0, 0, 0, 0.7);
          font-weight   : 400;
          border        : none;
          text-transform: uppercase;
          width         : 100%;
          background    : none;
          cursor        : pointer;
          &.active {
            color: rgba(0, 0, 0, 1);
          }
          svg {
            padding-left : 5px;
            padding-right: 5px;
            width        : unset;
            transition   : transform 0.25s ease-out;
          }
          .active {
            .top-drop & {
              color: purple !important;
            }
          }
        }
        &.show,
        &:hover {
          .nav-link {
            color: $zoonPrimary;
            svg {
              transform: rotate(180deg);
            }
          }
          ul.dropdown {
            visibility: visible;
            transform : translate(0, 0);
            opacity   : 1;
            z-index   : 0;
            width     : fit-content;
            width     : 100%;
            background: #eee;
            text-align: center;
            z-index   : 10;
            > li {
              -webkit-animation-name           : slideInLeft;
              animation-name                   : slideInLeft;
              -webkit-animation-duration       : 0.3s;
              animation-duration               : 0.3s;
              -webkit-animation-timing-function: ease-in-out;
              animation-timing-function        : ease-in-out;
              -webkit-animation-fill-mode      : backwards;
              animation-fill-mode              : backwards;
              height                           : auto;
              background                       : #f8f8f8;
              .nav-link {
                padding    : 0 20px;
                height     : 50px;
                line-height: 50px;
                color      : rgba(0, 0, 0, 0.7);
                white-space: nowrap;
                text-align : left;
                transition : all ease-out 0.1s;
                margin     : 0 5px;
                text-align : center;
                @media screen and (max-height: 600px) {
                  height     : 40px;
                  line-height: 40px;
                }
                &.active {
                  color: $zoonPrimary;
                  &:after {
                    display: none;
                  }
                }
                &:hover {
                  color  : $zoonPrimary;
                  padding: 0 10px 0 30px;
                }
              }
            }
          }
        }
        ul.dropdown {
          visibility                : hidden;
          display                   : flex;
          position                  : absolute;
          top                       : 55px;
          left                      : 0;
          min-width                 : 285px;
          right                     : 0;
          flex-direction            : column;
          margin                    : 0;
          padding                   : 0;
          list-style                : none;
          color                     : #333;
          background                : #fff;
          border-bottom-left-radius : 6px;
          border-bottom-right-radius: 6px;
          box-shadow                : 1px 2px 5px -1px rgba(0, 0, 0, 0.15), 0 4px 14px -1px rgba(0, 0, 0, 0.1);
          -webkit-transform         : translate(0, -60px);
          transform                 : translate(0, -60px);
          // transition: opacity 0.2s, z-index 0s 0.2s,
          //   -webkit-transform 0.2s ease-out;
          transition                : transform 0.2s ease-out, opacity 0.2s, z-index 0s 0.2s;
          // transition: transform 0.2s ease-out, opacity 0.2s, z-index 0s 0.2s,
          //   -webkit-transform 0.2s ease-out;
          opacity                   : 0;
          z-index                   : -1;
        }
        > li {
          font-size: 14px;
          cursor   : pointer;
        }
        @include media-breakpoint-down(md) {
          &.top-drop {
            button.drop {
              ul.dropdown {
                box-shadow: none;
              }
            }
            &.show {
              height: auto;
              button.drop {
                ul.dropdown {
                  position: relative;
                  top     : 0;
                }
              }
            }
          }
        }
      }
      li.nav-image {
        img {
          cursor : none;
          width  : 40px;
          padding: 0;
        }
      }
    }
    &.visible {
      margin-top      : 0;
      transition-delay: 0s;
      ul.navbar-nav {
        li.nav-item {
          .nav-link {
            &.active {
              color: $zoonPrimary;
              &:after {
                display: none;
              }
            }
          }
        }
        li.nav-image {
          display   : none;
          visibility: hidden;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .navbar-collapse {
      flex-basis      : 80%;
      margin-top      : 0;
      transition-delay: 0.25s;
      ul.navbar-nav {
        display       : flex;
        width         : auto;
        flex-direction: row;
        float         : right;
        li.nav-item {
          .nav-link {
            border: none;
            &.active {
              &:after {
                content   : "";
                position  : absolute;
                top       : -16px;
                width     : 100%;
                height    : 5px;
                background: $zoonPrimary;
                left      : 0;
              }
            }
            &.drop {}
            ul.dropdown {
              li {
                .nav-link {
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
    .navbar-toggler {
      display: none;
    }
  }
}