$zoonPrimary: #c5a47e;
$fontHeader: "Oswald", sans-serif;
$fontBody: "Open Sans", sans-serif; // $fontBody: "Montserrat", sans-serif;
$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px
); // Respond above.
@mixin media-breakpoint-up($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
@mixin media-breakpoint-down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
// breakpoint mixin
@mixin breakpoint($mq01: 0, $mq2: false, $maxmin: max-width) {
  @if $mq2 == false {
    @media ($maxmin: $mq01) {
      @content;
    }
  } @else {
    @media (min-width: $mq01) and (max-width: $mq2) {
      @content;
    }
  }
}
// responsive font size mixin
@mixin font-size-map($font-size-map) {
  @each $breakpoint, $font-size in $font-size-map {
    @if $breakpoint == null {
      font-size: $font-size;
    } @else {
      @include breakpoint($breakpoint) {
        font-size: $font-size;
      }
    }
  }
}
// breakpoint variables
// $lg: 1170px;
// $md: 1024px;
// $sm: 640px;
// $xs: 480px;
$lg: 1200px;
$md: 992px;
$sm: 768px;
$xs: 576px;
$html-font-size: (
  null: 16px,
  $md : 15px,
  $sm : 14px,
  $xs : 13px
);
$paragraph-font-size: (
  null: 18px,
  $lg : 17px,
  $md : 16px,
  $sm : 15px,
  $xs : 14px
);
html {
  @include font-size-map($html-font-size);
  // scroll-behavior: smooth;
}
p {
  @include font-size-map($paragraph-font-size);
}
body {
  margin                 : 0;
  padding                : 0;
  font-family            : $fontBody;
  visibility             : hidden;
  .wf-active & {
    visibility: visible;
  }
  font-size              : 1em;
  font-weight            : 300;
  line-height            : 1.65;
  color                  : #111;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width              : 380px;
  .root {
    height  : 100%;
    width   : 100%;
    position: absolute;
    top     : 0;
    left    : 0;
  }
}
h1,
h2,
h3,
h4,
h5 {
  font-family: $fontHeader;
  font-weight: 400;
}
a {
  color     : #000;
  transition: all ease-out 0.4s;
  &:hover {
    text-decoration: none;
    color          : $zoonPrimary;
  }
}
button {
  background: #fff;
  border    : 1px solid #ccc;
  box-shadow: none;
  outline   : none !important;
  &.plain-style-btn {
    border : none;
    padding: 0;
    &:hover {
      cursor: pointer;
    }
  }
}
img {
  max-width: 100%;
}
// .progressive-loaded {
//   animation: loadedImage ease-out forwards 0.5s;
//   @keyframes loadedImage {
//     0% {
//       opacity: 0.25;
//       filter: blur(10px);
//     }
//     100% {
//       opacity: 1;
//       filter: blur(0);
//     }
//   }
//   opacity  : 1;
// }
.wrapper {
  height  : 100%;
  width   : 100%;
  position: absolute;
  top     : 0;
  left    : 0;
  z-index : 5;
  @include media-breakpoint-down(sm) {
    min-width: 380px;
  }
  .content-wrapper {
    z-index       : 50;
    // z-index       : 20;
    transition    : all 400ms linear;
    vertical-align: top;
    position      : absolute;
    left          : 0;
    top           : 71px;
    // top: 70px;
    right         : 0;
    bottom        : 0;
    max-width     : 100%;
    overflow-x    : hidden;
    .trangroup-wrap {
      background: #fff;
      section {
        margin-top   : 0;
        // margin-bottom: 7rem;
        margin-bottom: 4rem;
        // padding-bottom: 3rem;
        background   : #fff;
        overflow     : hidden;
        &.fade-enter {
          opacity  : 0.01;
          transform: scale(0.9) translateY(-50%);
        }
        &.fade-enter-active {
          opacity   : 1;
          transform : scale(1) translateY(0%);
          transition: all 500ms ease-out;
        }
        &.fade-exit {
          opacity  : 1;
          transform: scale(1) translateY(0%);
        }
        &.fade-exit-active {
          opacity   : 0.01;
          transform : scale(0.9) translateY(50%);
          transition: all 500ms ease-out;
        }
        &.dots-bg {
          background: url("/images/patterns/dots.png") 0 0 repeat !important;
          header {
            .title-effect {
              visibility: hidden;
            }
            h1 {
              z-index : 100;
              position: relative;
            }
            .fade-title-left {
              mix-blend-mode: color-burn;
              opacity       : 0.6;
              color         : #222;
            }
          }
          article {
            &.white-bg {
              background: #fff;
            }
          }
        }
        .container {
          padding-left : 0;
          padding-right: 0;
        }
        header {
          text-align   : center;
          margin-bottom: 5rem;
          min-height   : 8rem;
          padding-top  : 3rem;
          .title-effect {
            position     : relative;
            display      : inline-block;
            height       : 125px;
            width        : 175px;
            margin       : -3rem auto 20px;
            border-bottom: 2px dashed rgba(0, 0, 0, 0.2);
            &:before {
              content     : "";
              position    : absolute;
              height      : 100%;
              width       : 0;
              border-right: 2px dashed rgba(0, 0, 0, 0.2);
              bottom      : 0;
              z-index     : 21;
            }
          }
          h1 {
            text-align    : center;
            margin        : 0;
            font-weight   : 400;
            letter-spacing: 0.32em;
            text-transform: uppercase;
            color         : #3d3d3d;
            span {
              color: $zoonPrimary;
            }
          }
          .sub-title {
            text-align   : center;
            margin-bottom: 0;
            font-weight  : 100;
            color        : #aaa;
          }
          .fade-title-left {
            position      : absolute;
            font-family   : "Montserrat", sans-serif;
            font-weight   : 700;
            font-size     : 5em;
            line-height   : 1;
            color         : #000;
            left          : 0;
            top           : 2em;
            opacity       : 0.035;
            text-transform: uppercase;
            white-space   : nowrap;
            transform     : translate(-5%, -50%);
            @include media-breakpoint-up(sm) {
              font-size: 11em;
              top      : 1em;
            }
          }
        }
        article {
          padding-bottom: 3rem;
          .article-title {
            margin-top   : 0;
            margin-bottom: 1.1em;
          }
          .article-text {
            margin-bottom: 0;
          }
          .service-item {
            margin-bottom: 2em;
          }
          form {
            display    : block;
            width      : 100%;
            max-width  : 1000px;
            margin-left: auto;
            .form-group {
              position     : relative;
              margin-bottom: 3rem;
              input:-webkit-autofill {
                -webkit-text-fill-color: $zoonPrimary !important;
              }
              input:-webkit-autofill,
              input:-webkit-autofill:active,
              input:-webkit-autofill:focus,
              input:-webkit-autofill:hover {
                -webkit-box-shadow: 0 0 0 30px white inset;
              }
              input,
              textarea {
                border-top      : none;
                border-right    : none;
                border-left     : none;
                border-bottom   : 1px solid #d1d1d1;
                padding-left    : 0;
                padding-right   : 0;
                min-height      : 40px;
                padding         : 6px 10px;
                background-color: transparent;
                border-radius   : 0;
                box-shadow      : none;
                outline         : none !important;
                display         : block;
                width           : 100%;
                transition      : all 0.4s linear;
                &.is-invalid-input {
                  border-bottom-color: #ff9a9a;
                }
                &.hidden {
                  visibility: hidden !important;
                  opacity   : 0 !important;
                  display   : none;
                }
                &:focus {
                  border-bottom-color: $zoonPrimary;
                  background         : #f8f8f8;
                  outline            : none !important;
                }
              }
              .checkbox-wrap {
                margin-top: -2rem;
                text-align: right;
                input {
                  min-height: 15px;
                  display   : inline-block;
                  width     : auto;
                }
                label {
                  line-height  : normal;
                  margin-bottom: 0;
                  padding-left : 5px;
                }
                @include media-breakpoint-down(md) {
                  margin-top: 0;
                }
              }
              svg {
                position       : absolute;
                top            : 10px;
                color          : #ff9a9a;
                opacity        : 0;
                animation      : iconAppear 1s forwards;
                animation-delay: 0.15s;
                @keyframes iconAppear {
                  0% {
                    opacity: 0;
                  }
                  100% {
                    opacity: 1;
                  }
                }
              }
              .invalid-feedback {
                position       : absolute;
                opacity        : 0;
                display        : block;
                animation      : iconAppear 1s forwards;
                animation-delay: 0.15s;
              }
              label.field-title {
                font-size : 10px;
                position  : absolute;
                right     : 0;
                margin-top: -20px;
                opacity   : 0;
                background: #d1d1d1;
                padding   : 0 10px;
                transition: all 0.4s ease-out;
                &.visible {
                  margin-top: 0;
                  opacity   : 1;
                }
              }
              @include media-breakpoint-down(md) {
                margin-bottom: 1.5rem;
              }
            }
            .form-section {
              position     : relative;
              background   : #f8f8f8;
              border       : 1px solid #ddd;
              padding      : 40px 40px 0;
              margin-bottom: 3rem;
              @include media-breakpoint-down(md) {
                padding: 30px 20px 0;
              }
              h5.section-title {
                position      : absolute;
                display       : block;
                width         : 100%;
                color         : #fff;
                font-size     : 4rem;
                text-shadow   : 0 0 10px rgba(200, 200, 200, 0.1);
                text-transform: uppercase;
                pointer-events: none;
                user-select   : none;
                top           : -15px;
                margin-left   : -40px;
                @include media-breakpoint-down(lg) {
                  font-size  : 2.75rem;
                  top        : -5px;
                  margin-left: -20px;
                }
              }
              input:-webkit-autofill,
              input:-webkit-autofill:active,
              input:-webkit-autofill:focus,
              input:-webkit-autofill:hover {
                -webkit-box-shadow: 0 0 0 30px #f8f8f8 inset;
              }
              input {
                &:focus {
                  background: none !important;
                }
              }
            }
            button {
              background    : #eee;
              color         : #333;
              transition    : all 0.4s linear;
              text-transform: uppercase;
              font-weight   : 100;
              padding       : 0.75rem;
              box-shadow    : none !important;
              &.form-submit {
                letter-spacing: 3px;
              }
              &:focus,
              &:hover {
                color     : #fff;
                background: $zoonPrimary;
              }
              &:disabled {
                color     : #fff;
                background: #f03434;
                cursor    : not-allowed;
              }
            }
          }
        }
      }
    }
    @include media-breakpoint-up(sm) {
      left: 60px;
    }
    @include media-breakpoint-up(md) {
      top: 72px;
    }
  }
}
body.edge {
  .wrapper {
    section {
      header {
        .fade-title-left {
          mix-blend-mode: normal !important;
          opacity       : 0.2 !important;
          color         : #ccc !important;
        }
      }
    }
  }
  .portal-slider {
    .range-wrap {
      input.position-range.vertical {
        height: 50px !important;
      }
    }
  }
}