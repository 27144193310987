@import "../../index.scss";
footer {
  position  : static;
  // position  : fixed;
  bottom    : 0;
  top       : auto;
  left      : 0;
  right     : 0;
  padding   : 40px 0 0;
  // z-index   : 20;
  z-index   : -1;
  border-top: 1px solid #eee;
  img.logo {
    height : 45px;
    width  : auto;
    display: block;
    margin : 0 auto 20px;
  }
  ul.footer-list {
    list-style: none;
    font-size : 14px;
    z-index   : 50;
    padding   : 0;
    &.legal {
      margin-bottom: 0;
      li {
        display      : inline-block;
        text-align   : center;
        padding-right: 20px;
      }
    }
  }
  @include media-breakpoint-up(md) {
    padding: 60px 0;
    img.logo {
      margin        : 0;
      padding-bottom: 0;
    }
    ul.footer-list {
      &.legal {
        li {
          text-align: left;
          display   : block;
        }
      }
    }
  }
  @include media-breakpoint-up(sm) {
    left: 60px;
  }
}